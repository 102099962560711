<template>
  <div class="container" v-if="!loading">
    <div class="mx-auto mt-3">
      <div class="card text-center">
        <div class="card-body">
          <h2 class="text-primary">Reservation Receipt</h2>
        </div>
      </div>
      <ReservationReceipt :orderReceipt="orderReceipt" />
      <div class="my-3">
        <button
          class="btn btn-primary mr-2 no-print"
          type="button"
          @click="goHome"
        >
          HOME
          <i class="align-middle pb-1 fas fa-fw fa-home"></i>
        </button>
        <button
          class="btn btn-secondary mr-2 no-print"
          type="button"
          @click="printScreen"
        >
          PRINT
          <i class="align-middle pb-1 fas fa-fw fa-print"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CartService from "@/services/CartService.js";
import ReservationReceipt from "@/components/reservation/ReservationReceipt.vue";

export default {
  name: "ReservationEditReceipt",
  title: "Reservation Edit Receipt",
  components: {
    ReservationReceipt
  },
  data() {
    return {
      orderReceipt: {}
    };
  },
  methods: {
    goHome() {
      this.$router.push("/").catch(() => {});
    },
    printScreen() {
      window.print();
    },
    getReceipt() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const ccpToken = this.$route.query.token;
      const orderId = this.$route.query.orderId;
      const service = new CartService(tenantId);
      service
        .getModificationReceipt(ccpToken, orderId)
        .then(response => {
          this.orderReceipt = response.data;
        })
        .finally(() => {
          this.$store.dispatch("transaction/clear");
          this.$store.commit("auth/setLoading", false);
        });
    }
  },
  computed: {
    loading() {
      return this.$store.getters["auth/loading"];
    }
  },
  created() {
    this.$store.commit("auth/setLoading", true);
    this.getReceipt();
  }
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  * {
    overflow: visible !important;
  }
  .page {
    page-break-after: always;
  }
}
</style>
